<template>
  <div
    ref="modal"
    :class="[listData.length ? 'pb-6' : 'pb-2.5', { 'h-search-modal': search }]"
    class="left-0 top-full flex max-h-search-modal w-full flex-col overflow-hidden bg-white pt-2.5"
  >
    <div class="flex h-full flex-col overflow-y-auto px-4">
      <div class="mx-auto flex h-full w-full flex-col overflow-y-auto">
        <div class="flex w-full items-center gap-2.5">
          <form
            class="flex w-full border"
            :class="
              errorMessage
                ? 'border-semantic-red-700'
                : 'border-neutral-black-300'
            "
            @submit.prevent="onSubmit"
          >
            <div class="relative flex-auto">
              <input
                ref="inputEl"
                v-model.trim="search"
                name="search"
                :placeholder="$t('search')"
                autocomplete="off"
                class="h-10 w-full pl-2.5 pr-8 ui-text-l text-neutral-black-300 placeholder-neutral-black-300 focus:outline-none"
                @keydown.esc="$emit('close')"
                @focus="handleFocus"
                @blur="handleBlur"
              />
              <button
                v-if="search"
                type="button"
                class="absolute right-2.5 top-1/2 inline-flex size-4 -translate-y-1/2 items-center justify-center"
                :class="errorMessage && 'text-semantic-red-700'"
                :aria-label="$t('clearInput')"
                @click="clearSearch"
              >
                <UiIcon name="close" class="size-6" />
              </button>
            </div>
            <button
              :type="isFocused || search ? 'submit' : 'button'"
              :class="
                cn({
                  'border-l border-neutral-black-300 bg-peach-600':
                    isFocused || search,
                  'border-border-semantic-red-700': errorMessage,
                })
              "
              :aria-label="$t('search')"
              class="inline-flex h-10 w-10.5 shrink-0 items-center justify-center"
            >
              <UiIcon name="search" class="size-6" />
            </button>
          </form>
          <button
            v-if="isFocused || search"
            type="button"
            class="text-[10px] uppercase text-neutral-black-300"
            @click="search = ''"
          >
            {{ $t("cancel") }}
          </button>
        </div>
        <LazySharedFieldHint
          v-if="errorMessage"
          :message="errorMessage"
          error
        />
        <LazyCommonAppHeaderSearchResult
          v-if="search && !errorMessage"
          :count="count"
          class="overflow-y-auto py-7.5"
          :search="search"
          :list-data="listData"
          :loading="loading"
          @result-click="search = ''"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

const inputEl = ref<HTMLInputElement>();
const searchForm = ref(null);
const isFocused = ref(false);
const emit = defineEmits([
  "close",
  "setSearchLength",
  "setListDataLength",
  "goToSearchPage",
]);

const { loading, listData, search, count, errorMessage, goToSearchPage } =
  useSearch();

onClickOutside(searchForm, () => {
  setTimeout(() => {
    emit("close");
  }, 0);
});

function clearSearch() {
  search.value = "";
  if (inputEl.value) {
    inputEl.value.focus();
  }
}

function handleFocus() {
  isFocused.value = true;
}
function handleBlur() {
  isFocused.value = false;
}
function onSubmit() {
  inputEl.value?.blur();
  goToSearchPage();
}
</script>
